import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Img from "gatsby-image"
import Layout from "../../components/Layout/Layout"
import SEO from "../../components/Seo/Seo"
import styled from 'styled-components'
import { saveAs } from 'file-saver';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';
import SectionPageMargin from '../../components/Layout/Section/SectionPageMargin'
import PageHeader from '../../components/Layout/PageHeader/PageHeader'
import Grid2 from '../../components/Layout/Grid2/Grid2'
import GridItem from '../../components/Layout/GridItem/GridItem'
import Heading2 from '../../components/Type/Heading2'
import Paragraph1 from '../../components/Type/Paragraph1'
import FlexCenter from '../../components/Layout/Flex/FlexCenter'
import ImageBox from '../../components/Layout/ImageBox/ImageBox'
import ReadyToGetStarted from '../../components/ReadyToGetStarted/ReadyToGetStarted'


const ZoomGaragePage = ({location}) => (
	<StaticQuery
		query={graphql`
			query  {
				allContentfulPage(filter: {contentful_id: {eq: "2oWgaZ28hKK3VZuCsPp1gy"}}) {
					edges {
					node {
						title
						content {
						content
						}
						contentReference {
						... on ContentfulItem {
							id
							title
							text {
							text
							}
							image {
							fluid (maxWidth: 300) {
								...GatsbyContentfulFluid_noBase64
							}
							file {
								url
							}
							}
						}
						}
					}
					}
				}
				placeholderImage: file(relativePath: { eq: "articles-zoom.png" }) {
					childImageSharp {
					fluid(maxWidth: 286) {
						...GatsbyImageSharpFluid
					}
					}
				}
			}
		`}

		render={data => {

			const downloader = (e, filepath, filename) => {

				if (e) {
					e.target.innerText = "ok"
				}

				trackCustomEvent({
					category: "Download",
					action: "Zoom Background",
					label: filename
				});

				saveAs('https:' + filepath + '?fm=jpg&q=80', filename);

			}
			const backgrounds = data.allContentfulPage.edges[0].node.contentReference.map((item, i) =>

				<Card key={i}>

					<BackgroundImage
						fluid={item.image.fluid}
						style={{
							backgroundSize: 'cover',
							backgroundPosition: 'center',
							backgroundRepeat: 'no-repeat',
							width: '300px',
							height: '200px'
						}}
					/>

					<Container>
						<Heading>{item.title}</Heading>
						<Credit>~ Photo credits ~</Credit>
						<div dangerouslySetInnerHTML={{ __html: item.text.text }} />
					</Container>
					<Download name={item.title} onClick={(e) => downloader(e, item.image.file.url, item.title + '.jpg')} >download</Download>
				</Card>
			);

			return (

				<Layout>

					<SEO title="Summon Zoom Garage"
						description="A collection of premium Zoom background car images."
						location={location}
					/>

					<PageHeader 
						title="Dream Garage Zoom Backgrounds" 
						text="Now that we’re spending all our days on Zoom calls, we thought it was an opportune
						time for you to subtly show off the contents of your garage… you know, the one
						conveniently located behind your home office of course."
					/>

					<SectionPageMargin>
						<Grid2>
							<GridItem>
								<Heading2>Ready to #humblebrag?</Heading2>
								<Paragraph1>
									To start, download your favourite Zoom Background from the choices below.
								</Paragraph1>
								<Paragraph1>
									To create a custom background in Zoom, either go to Preferences or select the ‘Virtual Background’ option from beside the ‘Start Video’ / ‘Stop Video’ button in the bottom menu. Then click on the + icon to upload your own image.
								</Paragraph1>
								<Paragraph1>
									Now try to keep a straight face as your friends and colleagues enviously study your garage.
								</Paragraph1>
								<Paragraph1>
									At Summon, we are now doing “virtual test-drives” over Zoom, allowing buyers of our prestige cars to look around, ask questions and get up close and personal with the car without leaving your own home. Summon is an Australian concierge service for selling your prestige car.
              					</Paragraph1>
							</GridItem>
							<GridItem>
								<FlexCenter>
									<ImageBox>
										<Img fluid={data.placeholderImage.childImageSharp.fluid} />
									</ImageBox>
								</FlexCenter>
								
							</GridItem>
						</Grid2>
					</SectionPageMargin>

					<SectionPageMargin>
						<BackgroundSection>
							{backgrounds}
						</BackgroundSection>
					</SectionPageMargin>

					<ReadyToGetStarted />
					
				</Layout>

			)
		}}
	/>
)

export default ZoomGaragePage


const BackgroundSection = styled.section`
    display: flex;
    flex-flow: row wrap;
	justify-content: space-between;

	@media (max-width: 650px) {
		justify-content: center;
	  } 

`;


const Card = styled.div`
  background-color: #ffffff;
  color: #000000;
  width: 300px;
  height: 369px;
  margin: 10px 0px 30px 0px;
  padding: 0px;
  border: 2px solid #3479ae;

  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0,0,0,0.24), 0 17px 50px 0 rgba(0,0,0,0.19);
  }
`;

const Container = styled.div`
  padding: 5px;
  height: 120px;
  text-align: center;
  
  
  p {
    color: black;
    margin: 0px;
    padding: 0px;
  }

  a {
    color: black;
  }
`;

const Heading = styled.span`
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 1.2em;
`;

const Credit = styled.span`
  colour: dark-grey;
  margin: 0px;
  padding: 0px;
  font-size: .9rem;
`;
const Download = styled.button`
  background: #3479ae;
  width: 100%;
  height: 40px;
  color: white;
  margin: 0px;
  padding: 0px;
  font-size: 1em;
  text-decoration: none;
  border: none;
  outline: none;
  text-align: center;
  cursor: pointer;

`;



